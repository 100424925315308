import {fetchKvpKey} from '@/api/kol';

export default (kvp_id) => ({
  data() {
    return {
      [kvp_id]: [],
    };
  },
  async mounted() {
    const res = await fetchKvpKey({kvp_id});
    this[kvp_id] = res.value;
  },
});
