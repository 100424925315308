import {getWechatSdkSign} from '@/api/wechat';
import * as ww from '@wecom/jssdk';

// 自定停止录音提前时间，防止未能调用停止录音时间获取语音文件失败
export const RECORD_AUTOSTOP_AHEAD_TIME = 3000;

class WechatSDK {
  async register() {
    const sign = await getWechatSdkSign();
    console.log(sign, 'sign');

    console.log(ww.register, 'wx.config');
    window.ww = ww;
    ww.register({
      corpId: sign.appid, // 必填，当前用户企业所属企业ID
      getConfigSignature, // 必填，根据url生成企业签名的回调函数
      onConfigSuccess: (res) => {
        console.log('onConfigSuccess');
        // alert(res)
      },
      onConfigFail: (res) => {
        // alert('onConfigFail');
        // alert(JSON.stringify(res));
        // alert(res)
      },
      onConfigComplete: (res) => {
        console.log('onConfigComplete');
      },
      jsApiList: [
        'startRecord',
        'stopRecord',
        'onVoiceRecordEnd',
        'playVoice',
        'pauseVoice',
        'stopVoice',
        'uploadVoice',
        'downloadVoice',
      ],
    });
    async function getConfigSignature() {
      // 根据 url 生成企业签名
      // 生成方法参考 https://developer.work.weixin.qq.com/document/path/90539
      return {
        timestamp: sign.timestamp,
        nonceStr: sign.noncestr,
        signature: sign.sign,
      };
    }
  }

  stopRecord() {
    clearTimeout(this.recordEndTimer);

    return ww.stopRecord({
      success: function (e) {
        this.localId = e.localId;
      },
    });
  }

  translateVoice(localId) {
    return ww.translateVoice({
      localId,
      isShowProgressTips: true,
    });
  }

  startRecord({onVoiceRecordEnd} = {}) {
    const result = ww.startRecord();
    this.recordEnded = false;

    if (typeof onVoiceRecordEnd === 'function') {
      this.recordEndTimer = setTimeout(() => {
        onVoiceRecordEnd();
      }, 60000 - RECORD_AUTOSTOP_AHEAD_TIME);
    }
    // ww.onVoiceRecordEnd({
    //   cancel: function () {
    //     alert('用户拒绝授权录音');
    //   },
    //   complete(e) {
    //     alert('自动停止');
    //     alert(JSON.stringify(e));
    //   },
    //   fail(e) {
    //     alert('自动停止');
    //     alert(JSON.stringify(e));
    //   },
    // });

    return result;
  }
}

export const wechatSDK = new WechatSDK();
