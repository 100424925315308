<template>
  <div>
    <div
      class="flex justify-center"
      @click="recording ? stopRecord() : startRecord()"
    >
      <van-button
        type="info"
        round
        style="width: 130px"
      ><van-icon
        :name="recording ? 'stop-circle' : 'volume-o'"
        style="margin-right: 6px"
      />{{ recording ? '停止录音' : '语音输入' }}</van-button>
    </div>
    <div
      v-if="recording"
      style="
        position: absolute;
        width: 100vw;
        height: 100vh;
        background-color: rgba(0, 0, 0, 0.75);
        z-index: 99;
        left: 0;
        top: 0;
      "
    >
      <circular-count-down-timer
        :interval="interval"
        :circles="circles"
        :main-circle-id="'1'"
        :show-value="false"
        style="
          position: absolute;
          left: calc(50vw - 65px);
          top: calc(50vh - 120px);
        "
        @update="handleUpdateCount"
      />
      <div
        style="
          position: absolute;
          width: 120px;
          height: 40px;
          left: calc(50vw - 60px);
          top: calc(50vh - 60px);
          font-size: 16px;
          color: #fff;
          text-align: center;
        "
      >
        <div v-if="secondLeft && secondLeft <= 10">{{ secondLeft }}s后停止</div>
        <span v-else> 录音中 </span>
      </div>
      <div
        style="
          position: absolute;
          width: 130px;
          left: calc(50vw - 65px);
          top: calc(50vh + 80px);
        "
      >
        <van-button
          type="info"
          round
          style="width: 130px"
          @click="recording ? stopRecord() : startRecord()"
        ><van-icon
          :name="recording ? 'stop-circle' : 'volume-o'"
          style="margin-right: 6px"
        />{{ recording ? '停止录音' : '语音输入' }}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {wechatSDK, RECORD_AUTOSTOP_AHEAD_TIME} from '@/utils/wechat';
import {Tab, Tabs, Picker, Popup, Icon, Button} from 'vant';
import {onvoicerecordend} from '@wecom/jssdk';
import CircularCountDownTimer from 'vue-circular-count-down-timer';

Vue.use(Button);
Vue.use(Icon);
Vue.use(CircularCountDownTimer);

const timeCircleAllSteps = 120 - RECORD_AUTOSTOP_AHEAD_TIME / 500;

export default {
  data() {
    return {
      recording: false,
      interval: 500,
      secondLeft: 0,
      circles: [
        {
          id: '1',
          steps: timeCircleAllSteps,
          stepLength: 1,
          size: 120,
          strokeWidth: 10,
          fillColor: 'transparent',
          strokeColor: '#1989fa',
          underneathStrokeColor: '#aaa',
        },
      ],
    };
  },

  destroyed() {
    if (this.recording) {
      this.stopRecord(false);
    }
  },
  mounted() {
    wechatSDK.register();
  },
  methods: {
    handleUpdateCount(e) {
      const stepNow = e[1];
      const leftStep = timeCircleAllSteps - stepNow;
      const leftStepSecond = (leftStep * 500) / 1000;
      if (Number.isInteger(leftStepSecond)) {
        this.secondLeft = leftStepSecond;
      }
    },
    async startRecord() {
      this.secondLeft = 0;
      this.recording = true;
      await wechatSDK.startRecord({
        onVoiceRecordEnd: () => {
          this.stopRecord();
        },
      });
    },
    async stopRecord(translate = true) {
      try {
        this.recording = false;
        const {localId} = await wechatSDK.stopRecord();
        if (!translate) return;
        const {translateResult} = await wechatSDK.translateVoice(localId);
        this.$emit('translateResult', translateResult);
      } catch (error) {
        this.recording = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
