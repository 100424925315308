<template>
  <div>
    <slot :list="list" :refresh="run"></slot>
    <Nodata v-if="!hasMore" :empty-txt="'没有更多数据了'" />
    <Nodata
      v-else
      :loading="loading || loadMoreLoading"
      :empty-txt="$t('no_data')"
    />
    <div
      v-if="!loadMoreLoading && !loading && hasMore"
      ref="elementToWatch"
      style="height: 100px"
    ></div>
  </div>
</template>

<script>
import Nodata from '@/components/Nodata';

export default {
  components: {
    Nodata,
  },
  props: {
    fn: Function,
    pageSize: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      loading: false,
      list: [],
      // 页数
      page: 0,
      loadMoreLoading: false,
      hasMore: true,
    };
  },
  mounted() {
    this.run();
  },
  beforeDestroy() {
    this.observer?.disconnect?.();
  },
  methods: {
    initPageIndex() {
      this.page = 0;
      this.hasMore = true;
    },
    observerReachBottom() {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      };

      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.loadMore();
            this.observer.disconnect();
            console.log('load more');
            // 在元素可见时执行相应的操作
          }
        });
      }, options);

      this.observer.observe(this.$refs.elementToWatch);
    },
    async run(...props) {
      this.loading = true;
      this.observer?.disconnect?.();
      this.initPageIndex();
      this.clear();
      try {
        const list = await this.fn(
          {page: this.page, pageSize: this.pageSize},
          ...props,
        );
        this.list = list ?? [];
        if (list.length) {
          this.$nextTick(() => {
            this.observerReachBottom();
          });
        }
      } finally {
        this.loading = false;
      }
    },
    async loadMore() {
      if (this.loadMoreLoading || !this.hasMore || this.loading) return;

      this.loadMoreLoading = true;
      try {
        this.page += 1;
        const list = await this.fn({page: this.page, pageSize: this.pageSize});
        this.list = [...this.list, ...(list ?? [])];
        if (!list.length) {
          this.hasMore = false;
        }
      } finally {
        this.loadMoreLoading = false;
        this.$nextTick(() => {
          this.observerReachBottom();
        });
      }
    },
    clear() {
      this.list = [];
    },
  },
};
</script>

<style scoped></style>
