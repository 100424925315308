<template>
  <div class="w-full">
    <MultiSelectDisplay
      :list="showList"
      @click="
        selected = multiple ? value?.map((item) => item[compareKey]) || [] : value;
        showPopup = true;
      "
      @close="multiple ? value.splice($event, 1) : (value = undefined)"
    />
    <van-popup v-model="showPopup" position="bottom">
      <div class="container" style="max-height: 60vh; overflow: auto">
        <div>
          <AsyncList :fn="fetchVUserMsls" :page-size="20">
            <template v-slot="{list, refresh}">
              <div
                style="
                  padding: 16px 0;
                  font-size: 16px;
                  position: sticky;
                  top: 0;
                  z-index: 99;
                  background-color: white;
                "
              >
                <div class="text-center font-bold flex justify-between">
                  <div class="van-picker__cancel" @click="showPopup = false">
                    取消
                  </div>
                  <div
                    class="van-picker__confirm"
                    @click="
                      showPopup = false;
                      $emit(
                        'input',
                        multiple ?
                          selected.map((item) =>
                            list.find(
                              (listItem) => listItem[compareKey] === item,
                            ),
                          ) : selected,
                      );
                    "
                  >
                    确认
                  </div>
                </div>
                <div>
                  <van-search
                    v-model="searchKey"
                    :clearable="false"
                    @search="refresh"
                  />
                </div>
              </div>
              <component
                :is="multiple ? 'van-checkbox-group' : 'van-radio-group'"
                v-model="selected"
              >
                <van-cell-group>
                  <van-cell
                    v-for="(item, index) in list"
                    :key="index"
                    clickable
                    @click="toggle(item, index)"
                  >
                    <template #title>
                      <span style="font-size: 16px">{{
                        `${item.user_name}`
                      }}</span>
                      <span
                        style="font-size: 12px; color: #999; margin-left: 10px"
                      >{{ item.position }}</span>
                    </template>
                    <template #right-icon>
                      <van-checkbox
                        v-if="multiple"
                        ref="checkboxes"
                        :name="item[compareKey]"
                      />
                      <van-radio v-else :name="item" />
                    </template>
                  </van-cell>
                </van-cell-group>
              </component>
            </template>
          </AsyncList>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue';
import {
  Cell,
  CellGroup,
  Checkbox,
  CheckboxGroup,
  Radio,
  RadioGroup,
  Search,
} from 'vant';
import AsyncList from '@/components/AsyncList.vue';
import {fetchVUserMsls} from '@/api/kol';
import MultiSelectDisplay from './MultiSelectDisplay.vue';

Vue.use(Search);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Checkbox);
Vue.use(CheckboxGroup);
Vue.use(Radio);
Vue.use(RadioGroup);

export default {
  components: {
    AsyncList,
    MultiSelectDisplay,
  },
  props: {
    multiple: {
      type: Boolean,
      default: true,
    },
    value: null,
    fn: Function,
    type: String,
  },
  data() {
    return {
      list: [],
      selected: [],
      showPopup: false,
      compareKey: 'user_id',
      searchKey: '',
    };
  },
  computed: {
    showList() {
      const list = Array.isArray(this.value)
        ? this.value
        : [this.value].filter(Boolean);

      return list.map((item) => item.user_name);
    },
  },
  methods: {
    fetchVUserMsls(pagination) {
      const fn = this.fn || fetchVUserMsls;
      return fn(this.searchKey, pagination);
    },
    toggle(item, index) {
      this.multiple
        ? this.$refs.checkboxes[index].toggle()
        : (this.selected = this.selected === item ? undefined : item);
    },
  },
};
</script>

<style lang="scss" scoped>
.container ::v-deep .van-cell__title {
  text-align: left;
}

.container ::v-deep .van-cell {
  display: flex !important;
  align-items: center;
}

::v-deep .van-cell__value {
  border: none !important;
  margin: none !important;
}
</style>
