var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"w-full"},[_c('MultiSelectDisplay',{attrs:{"list":_vm.valueList.map((item) => item.text)},on:{"click":function($event){_vm.showPopup = true},"close":function($event){_vm.activeIds = _vm.activeIds?.filter(
        (activeIdItem) => activeIdItem !== _vm.valueList[$event].id,
      );_vm.$emit('input', _vm.activeIds)}}}),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showPopup),callback:function ($$v) {_vm.showPopup=$$v},expression:"showPopup"}},[_c('div',{staticClass:"flex justify-between",staticStyle:{"margin":"10px 0"}},[_c('span',{staticClass:"van-picker__cancel",on:{"click":function($event){_vm.showPopup = false}}},[_vm._v("取消")]),_c('span',{staticClass:"van-picker__confirm",on:{"click":function($event){_vm.showPopup = false; _vm.$emit('input', _vm.activeIds)}}},[_vm._v("确认 ")])]),_c('van-tree-select',{attrs:{"items":[
        {
          text: '',
          children: _vm.items,
          dot: true,
        },
      ],"active-id":_vm.activeIds,"main-active-index":_vm.activeIndex},on:{"update:activeId":function($event){_vm.activeIds=$event},"update:active-id":function($event){_vm.activeIds=$event},"update:mainActiveIndex":function($event){_vm.activeIndex=$event},"update:main-active-index":function($event){_vm.activeIndex=$event},"click-item":function($event){return _vm.$emit('input', _vm.activeIds)}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }