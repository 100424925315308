import {Toast} from 'vant';

export const validateFields = (fieldsList, formState) => {
  const emptyField = fieldsList.find(
    (item) => item.required && !formState[item.field],
  );
  if (emptyField) {
    Toast(`${emptyField.label}不能为空`);
    return false;
  }
  return true;
};
