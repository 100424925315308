<template>
  <div class="w-full flex flex-wrap" style="min-height: 24px" @click="$emit('click')">
    <div
      v-for="(item, index) in list"
      :key="index"
      class="flex items-center"
      style="
        padding: 0px 8px;
        margin-right: 6px;
        font-size: 14px;
        border-radius: 2px;
      "
    >
      <span>
        {{ item }}
      </span>
      <van-icon
        name="cross"
        color="rgb(202, 65, 29)"
        style="margin-left: 6px"
        @click.stop="$emit('close', index)"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import {Icon} from 'vant';

Vue.use(Icon);

export default {
  props: {
    list: Array,
  },
};
</script>

<style lang="scss" scoped></style>
