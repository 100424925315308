<template>
  <div class="swipe-wrapper">
    <header class="header">
      <p class="h-title">{{ title }}</p>
      <i class="cubeic-back" @click="back">{{ backtxt }}</i>
      <Popover v-if="showContextMenu" v-model="showPopover" class="context-menu" trigger="click" placement="bottom-end" :actions="contextMenuActions" @select="contextMenuSelected">
        <template #reference>
          <Icon name="ellipsis" class="context-menu-button" size="24" />
        </template>
      </Popover>
    </header>
    <div class="scroll-container">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import {Icon, Popover} from 'vant';
export default {
  components: {
    Icon,
    Popover,
  },
  props: {
    hcpData: {
      type: Object,
      default() {
        return {};
      }
    },
    title: {
      type: String,
      default() {
        return '';
      }
    },
    backtxt: {
      type: String,
      default() {
        return '';
      }
    },
    showContextMenu: {
      type: Boolean,
      default() {
        return false;
      }
    },
    contextMenuActions: {
      type: Array,
      default() {
        return [];
      }
    },
  },
  data() {
    return {
      showPopover: false,
    };
  },
  mounted() {
  },
  methods: {
    back() {
      this.$emit('closeFn', false);
    },
    contextMenuSelected(event) {
      this.$emit('contextMenuFn', event?.text);
    },
  }
};
</script>
<style lang="scss" scoped>
.swipe-wrapper {
  $height: 44px;
  $scroll-height: calc(100% - 44px);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #f9f9fb;
  z-index: 99;
  .header {
    color: #2c3e50;
    position: relative;
    height: $height;
    line-height: $height;
    text-align: center;
    background-color: #edf0f4;
    box-shadow: 0 1px 6px #ccc;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    z-index: 5;
    .h-title {
      font-size: 14px;
    }
    .cubeic-back {
      font-size: 16px;
      position: absolute;
      top: 0;
      left: 0;
      padding: 0 15px;
      color: #2961a7;
    }
    .context-menu {
      position: absolute;
      top: 0;
      right: 0;
      .context-menu-button {
        font-size: 16px;
        padding: 10px 15px 0 15px;
        color: #666666;
      }
    }
  }
  .scroll-container {
    height: $scroll-height;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
</style>
